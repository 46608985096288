<template>
    <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
        
        <a-form layout='horizontal' 
            :form="form" 
            style="padding:0 24px" 
            :label-col="{span:5}" 
            :wrapper-col="{span:12}"
            @submit="handleSubmit"
            >
            <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
                <h4>Product</h4>
            </a-form-item>
            <a-form-item label="Name">
                <a-input
                    v-decorator="['name',{rules: [{ required:true}]}]"
                >
                </a-input>
            </a-form-item> 
            <a-form-item label="Description">
                <a-textarea
                    v-decorator="['description']"
                >
                </a-textarea>
            </a-form-item>       
            <a-form-item label="Unique ID">
                <a-input
                    v-decorator="['uniqueid',{rules: [{ required:true}]}]"
                >
                </a-input>
            </a-form-item>
            <a-form-item label="Url">
                <a-input
                    v-decorator="['url']"
                >
                </a-input>
            </a-form-item> 
            <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
                <a-button type="primary" html-type="submit" icon="save">Save</a-button>
                <a-space></a-space>
                <a-button type="secondary" icon="close" style="margin-left:10px" @click="backtoproductlist">Cancel</a-button>
            </a-form-item>
        </a-form>
    </a-card>
</template>
<script>
import {addorupdate, getitem} from '@/api/product';

export default ({
    methods: {
        backtoproductlist(){
            this.$router.go(-1);
        },
        handleSubmit(e){
            e.preventDefault();
            this.form.validateFields((err,values)=>{
                if (!err){
                    values.id=this.productid;
                    addorupdate(values).then(data=>{
                        this.$router.go(-1);
                    });
                }
            });
        }
    },
    data() {
        return {
            hello:'',
            name:'-',
            form: this.$form.createForm(this,{name: 'product'})

        };
    },
    props:{
        productid: String
    },
    mounted() {
        var id = this.productid;
        if (id==0){
            this.form.setFieldsValue(
                {
                    name:'',
                    description:"",
                    url:"",
                    uniqueid:''
                }
            );
        } else {
            getitem(id).then(data=>{
                this.form.setFieldsValue(
                    {
                        name: data.name,
                        description: data.description,
                        url: data.url,
                        uniqueid: data.uniqueid
                    }
                );
            });
        }
    }
})
</script>
